// Here you can add other styles
.breakWord {
  word-break: break-word;
}

.noMargin {
  margin: 0px;
}

.neg25MarginT {
  margin-top: -25px;
}

.neg25MarginB {
  margin-bottom: -25px;
}

.neg25PaddingB {
  padding-bottom: -25px;
}

.neg15PadL {
  margin-left: -16px;
}

.neg30PadL {
  margin-left: -30px;
}

.neg15PadR {
  margin-right: -15px;
}

.greenText {
  color: #4dbd74;
}

.redText {
  color: #f86c6b;
}

.bg-header {
  background-color: #f0f3f5;
  border: none;
}

.pointer {
  cursor: pointer;
}

.indent20 {
  text-indent: 20px;
}

.popover-inner {
  height: 100%;
}

.alert {
  margin-bottom: 0px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.modal-footer {
  justify-content: flex-start;
}
.modal-dialog {
  max-width: 550px;
}

.border-red {
  border: solid;
  border-color: #f86c6b;
}

.center {
  justify-content: center;
}

.sm-badge {
  height: 20px;
}

.width-100 {
  width: 100%
}

.tooltip-inner {
  background-color: gray
}

.scroll {
  overflow-y: auto;
  max-height: 88vh;
  overflow-x: auto
}

.hidden {
  display: none
}
.list-group-item-action{
  color: #23282c
}

.list-group-item-no-action{
  color: #5c6873
}

.scroll {
  overflow-y: auto;
  max-height: 88vh;
  overflow-x: auto;
  padding-bottom: 0px

}

ol, ul, dl {
  padding: .5rem;
}

.blackText {
  color: black
}

.buttonWidth {
  width: 68px;
  height: 28px
}

.larger {
  font-size: larger
}

.bg-white {
  background-color: white
}

.bg-info {
  background-color: #63c2de
}

.no-pad-top {
  padding-top: 0px
}

.no-pad-bottom {
  padding: 0px
}

.border {
  border: 1px
}

.height-under-toolbar {
  height: 66.5vh
}

.max-height-under-toolbar {
  max-height: 66.5vh
}

.height100 {
  height: 100%
}

.locked {
  opacity: .6
}

.font-15 {
  font-size: 15px
}

.max-50 {
  max-width: 50%
}

.min-80 {
  min-height: 80px
}

.noLeftPad {
  padding-left: 0px
}

.noRightPad {
  padding-right: 0px
}

.app-header .navbar-brand {
  left: 17%
}

.card-body {
  padding: 0px;
  margin: 0px;
  margin-right: -2px
}

.card-header:first-child {
  margin-bottom: -3px;
  margin-right: -2px
}

.card {
  margin-bottom: 0px
}

.app-body{
  margin-top: -4px
}

.height-50 {
  height: 50%;
  max-height: 50%
}

.height-100 {
  height: 100%
}

.width-50 {
  width: 50%
}

.row {
  margin: 0px
}

.border-bottom {
  border-bottom: 2px solid black !important
}

.main-height {
  height: 90vh
}

.padding-right {
  padding: 10px !important
}

body {
  text-align: center
}

.card-header {
  text-align: center
}

.form-group {
  margin-bottom: 0
}

.nav-link.active a {
  color: white;
  text-decoration: none;
  background-color: transparent;
}